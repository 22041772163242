import ModalWrapper from '@/components/ModalWrapper';
// import router from '@/router';

export default {
    install(Vue, router) {

        const modals = new (Vue.extend({router, ...ModalWrapper}));
        Vue.prototype.$modals = {
            open: modals.open,
            close: modals.close,
            confirm: modals.confirm,
            prompt: modals.prompt
        }

        const div = document.createElement('div')
        document.body.appendChild(div);

        modals.$mount(div);

        // router.beforeEach((to, from, next) => {
        //     Vue.prototype.$modals.close();
        //     next();
        // });

    }
}
<template>
    <div id="app">
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
        <button class="scroll-top" aria-label="Scroll to top" :class="{'visible': scrollTopVisible }" type="button" @click="scrollToTop">
            <BaseIcon name="caret_up" />
        </button>
    </div>
</template>
<script>
export default {
    data(){
        return {
            scrollTopVisible: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.catchScroll, {passive: true});
    },
    methods: {
        catchScroll () {
            const pastTopOffset = window.pageYOffset > 300;
            this.scrollTopVisible = pastTopOffset;
        },
        scrollToTop(){
			window.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            });
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.catchScroll);
    }
}
</script>